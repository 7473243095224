/* #app {
    height: 100%;
    margin: auto;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
} */

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: black;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

  .flip-h {
      transform: scale(-1, 1);
  }

  .term-text {
      animation: term-text 6.5s step-end infinite alternate;
      z-index: 99;
  }

  .term-text a {
      padding: .5em;
  }
  .term-link:hover {
    text-decoration:underline;
  }

  @keyframes term-text {

      0%,
      100% {
          /* background-color: rgba(255, 255, 255, 0); */
          color: #757575;
          text-shadow:
              0.025em 0.04em 0 #fffc00;
      }

      20% {
          /* background-color: rgba(0, 0, 0, 0.074); */
          color: limegreen;
      }

      30% {
          /* background-color: #fff; */
          color: limegreen;
      }

      40% {
          /* background-color: #000; */
          color: limegreen;
          text-shadow: -0.03em -0.04em 0 #fc00ff,
              0.025em 0.04em 0 #00ff04;
      }

      /* 45%{
      background-color: #fff;
      color: #757575;
    } */
      55% {
          /* background-color: #000; */
          color: #868686;
          text-shadow: 0.05em 0 0 #ffe70b, 0.025em 0.04em 0 #00ff04;
      }

      60% {
          /* background-color: rgba(0, 0, 0, 0.224); */
          color: #868686;
          text-shadow: 0.025em 0.04em 5 #00ff04;
          /* background-color: #fff;
      color: #757575; */
      }
  }
  #term-button-cls {
      display: flex;
      position: absolute;
      left: 0.125rem;
      top: 0.1rem;
      width: 8px;
      height: 8px;
      border-radius: 20px;
      background-color: #f66969;
      padding: 0.25em;
      cursor: pointer;
      font-family: futura bold;
      /* content: "x"; */
      align-items: center;
      text-align: center;
      /* align-content: center; */
      justify-content: center;
      margin: 0;
      z-index: 1999;
  }
  * {
      transition: 0.2s ease-in-out;
  }

  #portrait ,.small-image{
        /* position: relative; */
      animation: floater 3s linear infinite;
  }




@-moz-keyframes floater {

    0%,
        100% {
            /* bottom: 0; */
            -moz-transform: rotate(-2deg);
        }

    25% {
        -moz-transform: translateY(-1rem);
    }
        45% {
            -moz-transform: rotate(0deg);
        }
    50% {
        /* bottom: 25px; */
        -moz-transform: translateY(0rem);

    }

    75% {
        -moz-transform: rotate(-5deg);
        -moz-transform: translateY(1rem);
    }
}

@-webkit-keyframes floater {

    0%,
    100% {
        /* bottom: 0; */
        -webkit-transform: rotate(-2deg);
    }

    /* 20% {
        -webkit-transform: rotate(5deg);
    } */
    25% {
        /* -webkit-transform: rotate(5deg); */
        -webkit-transform: translateY(-1rem);
    }
        45% {
            -webkit-transform: rotate(0deg);
        }
    50% {
        /* bottom: 25px; */
        -webkit-transform: translateY(0rem);
    }

    75% {
        -webkit-transform: rotate(-5deg);
        -webkit-transform: translateY(1rem);
    }
}

@keyframes floater {

    0%,
    100% {
        /* bottom: 0; */
        transform: rotate(-2deg);
    }
        /* 20% {
        transform: rotate(2deg); 
        } */
    
    25% {
        /* transform: rotate(5deg); */
        transform: translateY(-1rem);
        /* transform: translateY(-5rem); */
    }
    
    45% {
    transform: rotate(0deg);
    }
    50% {
        /* bottom: 25px; */
        /* transform: rotate(0deg); */
        transform: translateY(0rem);
    }
    65% {
    /* transform: rotate(2deg); */
    }
    
    75% {
        /* transform: rotate(-5deg); */
        transform: translateY(1rem);
    }
}

.small-image {
    z-index:0;
    top: 45%;
    left: 36%;
    /* transform: scale(0.4);  */
    /* animation: orbit 10s linear infinite; */ 
    position: absolute;
    height: 10%;
    width: auto;
    /* position: absolute; */
        /* width: 40px;
        height: 40px; */
        /* background-color: #f39c12; */
        /* Orange color for the moon */
        /* border-radius: 50%; */
    animation: rotateMoon 13s linear infinite;
    transition-timing-function: ease-in;


}
    /*******************
    Scroll Text
    *****************/
                #scroll-container,
                div[id*="-scroll-container"],
                #hover-scroll-container:hover,
                #hover-scroll-container:focus
        
                /* ,#hover-scroll-container:active */
                    {
                    /* border: 3px solid black; */
                    margin: auto;
                    white-space: nowrap;
                    border-radius: 5px;
                    overflow: hidden;
                    width: 95%;
                    height: 1.5em;
                }
        
                div[id*="-scroll-container"],
                #hover-scroll-container:hover,
                #hover-scroll-container:focus
        
                /* #hover-scroll-container:active  */
                    {
                    /* color: yellow; */
                    white-space: nowrap;
                    width: 100%;
                }
        
                /* #scroll-text:hover { */
        
                #scroll-text
        
                /* #hover-scroll-text:hover,
          #hover-scroll-text:focus
         ,#hover-scroll-text:active  */
                    {
                    /* animation properties */
                    -moz-transform: translateX(100%);
                    -webkit-transform: translateX(100%);
                    transform: translateX(100%);
        
                    -moz-animation: my-animation 23s linear infinite;
                    -webkit-animation: my-animation 23s linear infinite;
                    animation: my-animation 23s linear infinite;
                }
        
                /* for Firefox */
                @-moz-keyframes my-animation {
                    from {
                        -moz-transform: translateX(100%);
                    }
        
                    to {
                        -moz-transform: translateX(-100%);
                    }
                }
        
                /* for Chrome */
                @-webkit-keyframes my-animation {
                    from {
                        -webkit-transform: translateX(100%);
                    }
        
                    to {
                        -webkit-transform: translateX(-100%);
                    }
                }
        
                @keyframes my-animation {
                    from {
                        -moz-transform: translateX(100%);
                        -webkit-transform: translateX(100%);
                        transform: translateX(100%);
                    }
        
                    to {
                        -moz-transform: translateX(-100%);
                        -webkit-transform: translateX(-100%);
                        transform: translateX(-100%);
                    }
                }
    
.path {
    position: absolute;
    width: 200px;
    height: 2px;
    background-color: #ecf0f1;
    /* Light gray color for the path */
    top: 50px;
    left: 0;
    transform-origin: left center;
    z-index: 0;
    /* Place the path behind both planet and moon */
    animation: rotatePath 10s linear infinite;
}
@keyframes orbit {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes rotateMoon {
    from {
        transform: rotate(0deg) translateX(150px) rotate(0deg);
    }

    to {
        transform: rotate(-360deg) translateX(150px) rotate(360deg);
        z-index: 3;
    }
}

@keyframes rotatePath {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/* @keyframes rotateMoon {
    from {
        transform: rotate(0deg) translateY(-40%) translateY(250px) rotate(0deg) ;
    }
  
     to {
        z-index: 0;
        transform:  rotate(-360deg) translateY(-40%) translateY(250px)  rotate(360deg) ;

    }
}
@-moz-keyframes rotateMoon {
    from {
       -moz-transform: rotate(0deg) translateY(-40%) translateX(200px) rotate(0deg) ;
    }
   
    to {
        z-index: 3;
        -moz-transform:  rotate(-360deg) translateY(-40%) translateX(200px)  rotate(360deg) ;

    }
}
@-webkit-keyframes rotateMoon {
    from {
       -webkit-transform: rotate(0deg) translateY(-40%) translateX(200px) rotate(0deg) ;
    }
   
    to {
        z-index: 3;
        -webkit-transform:  rotate(-360deg) translateY(-40%) translateX(200px)  rotate(360deg) scale(2);

    }
}  */

.typing {
  /* width: 35ch; */
  animation: typing 2s steps(37), blink2 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  /* font-family: monospace; */
  font-size: 1em;
}
.typing-med{
  /* width: 35ch; */
  animation: typing 2s steps(20), blink2 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  /* font-family: monospace; */
  font-size: 1em;
}
@keyframes typing {
  from {
    width: 0;
  }
  /* to{
        width: 75%;
    } */
}
@keyframes blink2 {
    50% {
        border-color: transparent;
    }
}

.hover-shake:hover,
#intro-button:hover,
#help-button:hover,
.sandwich:hover {
    animation: hover-shake 0.5s infinite ease-in-out
}
.hover-shake2:hover {
    animation: hover-shake2 0.5s infinite ease-in-out
}

@keyframes hover-shake {

    0%,
    100% {
        transform: rotate(0deg);
        align-items: left;
    }

    25% {
        transform: rotate(10deg);

    }

    75% {
        transform: rotate(-10deg);
        align-items: center;

    }
}
@keyframes hover-shake2 {

    0%,
    100% {
        transform:  translate(-50%,0) rotate(-10deg);
        /* align-items: left; */
    }

    25% {
        transform:  translate(-50%, 0) rotate(-10deg);

    }

    75% {
        transform:  translate(-50%, 0) rotate(-10deg);
        /* align-items: center; */

    }
}
.blink {
    animation: blinker 2s ease-in-out infinite;
}

.blink-2 {
    animation: blinker 0.75s ease-in-out infinite;
}
.blink-3 {
    animation: blinker 6s ease-in-out infinite;
}

@keyframes blinker {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

.just-moving {
    /* cursor:'pointer'; */
   
    animation: up-down 2s infinite ease-in-out;
}
.just-moving-alt {
    /* cursor:'pointer'; */
   
    animation: down-up 2s infinite ease-in-out;
}

@keyframes down-up {

    0%,
    100% {
        transform: translate(0,0);
    }

    25% {
        transform:translate(0, -5px);

    }

    75% {
        transform: translate(0, 5px);

    }
}
@keyframes up-down {

    0%,
    100% {
        transform: translate(0,0);
    }

    25% {
        transform:translate(0, 5px);

    }

    75% {
        transform: translate(0, -5px);

    }
}
/********
Zooom
*********/




/********
Zooom
*********/
.magnifying-glass {
    display: flex;
    width: 50vw;
    background: black;
}

.magnifying-glass__img {
    width: 100%;
}

.magnifying-glass__magnifier {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    width: 15vw;
    max-width: 10rem;
    height: 15vw;
    max-height: 10rem;
    border: 5px solid rgba(white, 0.25);
    border-radius: 50%;
    background-color: transparent;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.magnifying-glass__enlarged-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
}
.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noSelect:focus {
    outline: none !important;
}

.spinner {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* transform: rotate(3deg); */
    /* transform: rotate(0.3rad);/ */
    /* transform: rotate(3grad); */
    /* transform: rotate(.03turn);  */
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.woddle {
    animation: hover-shake 0.5s infinite ease-in-out
}
.woddle-long {
    animation: hover-shake 2s infinite ease-in-out
}

@keyframes hover-shake {

    0%,
    100% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);

    }

    75% {
        transform: rotate(-10deg);

    }
}